import React, {useState} from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import {observer} from 'mobx-react';
import {useStores} from 'hooks/useStores';
import {Box, Grid, Radio, Button} from '@mui/material/';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers';
import BaseDatePicker, {DatePickerValue} from 'components/BaseComponents/BaseDatepicker';
import BaseDatepickerInput from 'components/BaseComponents/BaseDatepickerInput';

const DEFAULT_STATE = {
  filterType: 'pre-defined',
  isOpened: false,
};

const ProjectsDateFilter = observer(() => {
  const {LmDashboardProjectsStore: store} = useStores();
  const [filterData, setFilterData] = useState<any>(DEFAULT_STATE);
  const today = new Date();

  const handleFilterChange = (type: string, value: string | boolean) => {
    setFilterData({...filterData, [type]: value});
  };

  const getFormattedDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
  };

  const handleDateFilterRangeChange = (
    rangeName: DatePickerValue,
    type?: 'from' | 'to',
    customDate?: DatePickerValue
  ) => {
    const currentDate = dayjs();
    const todayFormatted: string = currentDate.format('YYYY-MM-DD');

    switch (rangeName) {
      case 'this-week':
        const weekStartDay: string = currentDate.clone().startOf('week').format('YYYY-MM-DD');
        store.changeFilterDateRange('This Week', weekStartDay, todayFormatted);
        return;
      case 'this-month':
        const monthStartDay: string = currentDate.clone().startOf('month').format('YYYY-MM-DD');
        store.changeFilterDateRange('This Month', monthStartDay, todayFormatted);
        return;
      case 'this-half-year':
        const halfYearStartDay: string = currentDate.clone().subtract(6, 'months').format('YYYY-MM-DD');
        store.changeFilterDateRange('Six months', halfYearStartDay, todayFormatted);
        return;
      case 'this-year':
        const yearStartDay: string = currentDate.clone().startOf('year').format('YYYY-MM-DD');
        store.changeFilterDateRange('This Year', yearStartDay, todayFormatted);
        return;
      case 'all-time':
        store.changeFilterDateRange('All Time', '', '');
        return;
      case 'custom-range':
        const {dateFilter} = store;
        const newDateFilter = {...dateFilter};
        if (type && customDate) {
          newDateFilter[type] = dayjs(customDate).format('YYYY-MM-DD');
          const fromText = newDateFilter.from ? getFormattedDate(newDateFilter.from) : 'to';
          const toText = newDateFilter.to ? getFormattedDate(newDateFilter.to) : 'today';
          newDateFilter.textValue = fromText + ' - ' + toText;
          store.changeFilterDateRange(fromText + ' - ' + toText, newDateFilter.from || '', newDateFilter.to || '');
        }
        return;
      default:
        return;
    }
  };

  return (
    <Wrapper>
      <Button onClick={() => handleFilterChange('isOpened', !filterData.isOpened)}>
        Date: {store.dateFilter.textValue}
      </Button>
      <Box className={`date-filter-content ${!filterData.isOpened && 'date-filter-content--hidden'}`}>
        <Box onClick={() => handleFilterChange('filterType', 'pre-defined')}>
          <Radio checked={filterData.filterType === 'pre-defined'} />
          <PredefinedButton
            variant="contained"
            onClick={() => handleDateFilterRangeChange('this-week')}
            className={`predefined-left`}
          >
            This Week
          </PredefinedButton>
          <PredefinedButton variant="contained" onClick={() => handleDateFilterRangeChange('this-month')}>
            This Month
          </PredefinedButton>
          <PredefinedButton variant="contained" onClick={() => handleDateFilterRangeChange('this-half-year')}>
            Last 6 months
          </PredefinedButton>
          <PredefinedButton variant="contained" onClick={() => handleDateFilterRangeChange('this-year')}>
            This Year
          </PredefinedButton>
          <PredefinedButton
            variant="contained"
            onClick={() => handleDateFilterRangeChange('all-time')}
            className={`predefined-right`}
          >
            All time
          </PredefinedButton>
        </Box>
        <DatePickerWrapper onClick={() => handleFilterChange('filterType', 'custom-range')}>
          <Radio checked={filterData.filterType === 'custom-range'} />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid className="grid-wrapper">
              From
              <DatePicker
                inputFormat="YYYY-MM-DD"
                disabled={filterData.filterType !== 'custom-range'}
                value={store.dateFilter.from || today}
                onChange={(date) => handleDateFilterRangeChange('custom-range', 'from', date)}
                renderInput={(inputProps) => <BaseDatepickerInput {...inputProps} />}
              />
              To
              <DatePicker
                inputFormat="YYYY-MM-DD"
                disabled={filterData.filterType !== 'custom-range'}
                value={store.dateFilter.to || today}
                onChange={(date) => handleDateFilterRangeChange('custom-range', 'to', date)}
                renderInput={(inputProps) => (
                  <BaseDatepickerInput {...inputProps} aria-label={'change date'} id="projects-filter-date-end" />
                )}
              />
            </Grid>
          </LocalizationProvider>
        </DatePickerWrapper>
        <DatePickerFooter>
          <Button className="reset-filter" onClick={() => handleDateFilterRangeChange('this-half-year')}>
            RESET
          </Button>
        </DatePickerFooter>
      </Box>
    </Wrapper>
  );
});

const Wrapper = styled(Box)`
  position: relative;
  background: ${({theme}) => theme.colors.grey050};
  display: flex;
  justify-content: center;

  & .date-filter-content {
    position: absolute;
    top: 100%;
    background: ${({theme}) => theme.colors.grey000};
    z-index: 2;
    box-shadow: 5px 5px 9px -7px ${({theme}) => theme.colors.grey100};
    padding: 10px;
    border-radius: 5px;
    min-width: 600px;

    &--hidden {
      display: none;
    }
  }
`;

const PredefinedButton = styled(Button)`
  border-radius: 0;
  box-shadow: none;
  border: 1px solid ${({theme}) => theme.colors.grey070};
  color: ${({theme}) => theme.colors.grey070};
  background: ${({theme}) => theme.colors.grey000};

  &.predefined-left {
    border-radius: 4px 0 0 4px;
  }

  &.predefined-right {
    border-radius: 0 4px 4px 0;
  }
`;

const DatePicker = styled(BaseDatePicker)`
  max-width: 180px;
  margin: 0 10px;
`;

const DatePickerFooter = styled(Box)`
  color: ${({theme}) => theme.colors.grey000};
  margin-top: 10px;

  &.reset-filter {
    background: ${({theme}) => theme.colors.grey070};
    color: inherit;
  }

  &.apply-filter {
    background: ${({theme}) => theme.colors.green054};
    color: inherit;
  }
`;

const DatePickerWrapper = styled(Box)`
  margin-top: 10px;
  display: flex;
  align-items: center;

  & .grid-wrapper {
    display: flex;
    align-items: center;
  }
`;

export default ProjectsDateFilter;
