import React, {FC, useEffect} from 'react';
import styled from 'styled-components';
import {autorun} from 'mobx';
import {observer} from 'mobx-react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import ListItemText from '@mui/material/ListItemText';
import InProgress from './widgets/InProgress';
import Waiting from './widgets/Waiting';
import LowQuality from './widgets/LowQuality';
import LowQa from './widgets/LowQa';
import LowLL from './widgets/LowLL';
import Delayed from './widgets/Delayed';
import Translators from './widgets/Translators';
import WaitingPilot from './widgets/WaitingPilot';
import Expired from './widgets/Expired';
import WaitingLL from './widgets/WaitingLL';
import StatisticLL from './widgets/StatisticLL';
import Tickets from './widgets/Tickets';
import {SourceLanguage} from 'pages/Wizard/types';
import {useStores} from 'hooks/useStores';
import {regularWeight} from '../../../theme/fonts';

const Dashboard: FC = observer(() => {
  const {LmDashboardMainStore: store} = useStores();

  useEffect(
    () =>
      autorun(() => {
        store.getLanguagesList();
        store.loadFilters();
        store.getDelayedProjects();
        store.getWaitingProjects();
        store.getLowQualityProjects();
        store.getInProgerssProjects();
        store.getLowQAProjects();
        store.getTranslatorsProjects();
        store.getLowLLProjects();
        store.getExpiredProjects();
      }),
    []
  );

  const changedSource = (event: any) => {
    store.setSourceLanguage(event.target.value);
  };

  const changedTarget = (event: any) => {
    store.setTargetLanguage(event.target.value);
  };

  const changedClient = (event: any) => {
    store.setClient(event.target.value);
  };

  return (
    <>
      <Grid container>
        <Grid item md={2} sm={6} xs={12}>
          <FormControlItem variant="outlined">
            <TextInput
              id="client"
              value={store.client}
              label="ID or E-mail"
              variant="outlined"
              onChange={changedClient}
            />
          </FormControlItem>
        </Grid>
        <Grid item md={4} sm={6} xs={6}>
          <FormControlItem variant="outlined" className="multi-select">
            <Label id="source-select-outlined-label">Source</Label>
            <StyledSelect
              labelId="source-select-helper-label"
              id="sourceLanguage"
              multiple
              value={store.sourceLanguage}
              label="Source"
              onChange={changedSource}
              input={<Input />}
              renderValue={function (selected: any) {
                const names: string[] = [];
                selected.forEach(function (langID: number) {
                  const found = store.languagesList.find((element: SourceLanguage) => element.id === langID);
                  if (found) {
                    names.push(found.name);
                  }
                });

                return names.join(', ');
              }}
            >
              <MenuItem value="">Select language</MenuItem>
              {store.languagesList.map((language: SourceLanguage) => (
                <MenuItem key={language?.id} value={language?.id}>
                  <Checkbox checked={store.sourceLanguage.indexOf(language?.id) > -1} />
                  <ListItemText primary={language?.name} />
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControlItem>
        </Grid>
        <Grid item md={4} sm={6} xs={6}>
          <FormControlItem variant="outlined" className="multi-select">
            <Label id="target-select-outlined-label">Target</Label>
            <StyledSelect
              labelId="target-select-helper-label"
              id="targetLanguage"
              multiple
              value={store.targetLanguage}
              label="Target"
              onChange={changedTarget}
              input={<Input />}
              renderValue={function (selected: any) {
                const names: string[] = [];
                selected.forEach(function (langId: number) {
                  const foundLang = store.languagesList.find((element: SourceLanguage) => element.id === langId);
                  if (foundLang) {
                    names.push(foundLang.name);
                  }
                });

                return names.join(', ');
              }}
            >
              <MenuItem value="">Select language</MenuItem>
              {store.languagesList.map((language: SourceLanguage) => (
                <MenuItem key={language?.id} value={language?.id}>
                  <Checkbox checked={store.targetLanguage.indexOf(language?.id) > -1} />
                  <ListItemText primary={language?.name} />
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControlItem>
        </Grid>
        <Grid item md={2} xs={6}>
          <ClearAll onClick={store.clearFilters}>Clear all</ClearAll>
        </Grid>
      </Grid>
      <Container container>
        <Waiting />
        <LowQuality />
        <InProgress />
        <LowQa />
        <Delayed />
        <Translators />
        <WaitingPilot />
        <LowLL />
        <Expired />
        <WaitingLL />
        <StatisticLL />
        <Tickets />
      </Container>
    </>
  );
});

const FormControlItem = styled(FormControl)`
  width: 100%;
  margin: 0 0 20px;

  & .MuiInputLabel-outlined {
    transform: translate(14px, 16px) scale(1);
  }

  & .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }

  &.multi-select {
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 5px;

    & .MuiInputLabel-shrink {
      background: ${({theme}) => theme.colors.grey000};
      padding: 0 5px;
    }

    & label + .MuiInput-formControl {
      margin-top: 0;
    }
    
    & label + .MuiInput-root {
      margin-top: 3px;
    }

    & .MuiSelect-select.MuiSelect-select {
      padding: 0.8rem 0.9rem 0.9rem;
    }

    & .MuiInput-underline:before {
      border: none;
    }
  }
`;

const ClearAll = styled.span`
  ${regularWeight};
  color: ${({theme}) => theme.colors.red100};
  font-size: 14px;
  padding: 0.9rem;
  display: block;

  & :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const Container = styled(Grid)`
  &.MuiGrid-container {
    max-width: 1100px;
    margin: 0 auto;
    padding-bottom: 100px;
    font-size: 0.9rem;

    & .MuiGrid-item {
      padding: 0 2px 4px;
    }

    & .btn.active {
      background-color: ${({theme}) => theme.colors.orange090};
      border-color: ${({theme}) => theme.colors.orange090};
      color: ${({theme}) => theme.colors.grey000};

      &:focus {
        box-shadow: none;
      }
    }
  }
`;

const TextInput = styled(TextField)`
  &.MuiTextField-root {
    input {
      padding: 16.5px 14px;
      color: ${({theme}) => theme.colors.grey085};
      
      &::placeholder {
        color: ${({theme}) => theme.colors.grey085};
      }
    }
  }
`;

const StyledSelect = styled(Select)`
  &.MuiSelect-root {
    label {
      margin-top: 0;
    }
  }
`;

const Label = styled(InputLabel)`
  &.MuiInputLabel-root {
    margin-top: 0;
    color: ${({theme}) => theme.colors.grey085}
  }
`;

export default Dashboard;
