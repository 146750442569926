export default {
  ALERT_TEXT:
    'Attention! Your profile is marked as "On Vacation" and all notifications have been turned off. You can change the status',
  ALERT_LINK: 'here',
  ALLOCATE_SUCCESSFUL: 'Allocation was successful',
  UNABLE_TRANSFER: 'Unable to transfer credits',
  THANK_YOU: 'Thank You For Registering.',
  GET_CONTENT: "Let's get your content translated",
  NOTIFICATION_REFERENCES_TITLE: 'Professional References',
  NOTIFICATION_REFERENCES_LABEL: 'Receive special offers and updates',
  NOTIFICATION_REFERENCES_NAME: 'email_promotion',
  QA_NOTIFICATION_REFERENCES: 'special-offers-and-updates-checkbox',
  RECEIVE_FILES_TITLE: 'Receive my translations:',
  RECEIVE_FILES_LABEL: 'Send me translated files by email',
  RECEIVE_FILES_NAME: 'email_project_files_attach',
  QA_RECEIVE_FILES: 'translated-files-checkbox',
  ALLOW_NOTIFICATION_LABEL: 'Allow notifications about projects you translate',
  ALLOW_NOTIFICATION_NAME: 'email_translatorproject',
  QA_ALLOW_NOTIFICATION: 'notifications-about-projects-checkbox',
  NEW_PROJECT_NOTIFICATION_LABEL: 'Receive new projects notifications and job offers',
  NEW_PROJECT_NOTIFICATION_NAME: 'email_newproject',
  QA_NEW_PROJECT_NOTIFICATION: 'new-projects-notifications-checkbox',
  ALL_NOTIFICATION_EVENT: 'All notifications',
  ALL_NOTIFICATION_DESCRIPTION: 'Allow notifications about projects you initiate',
  ALL_NOTIFICATION_NAME: 'email_yourproject',
  QA_ALL_NOTIFICATION: 'notifications-about-projects-checkbox',
  PROJECT_STARTED_EVENT: 'Project started',
  PROJECT_STARTED_DESCRIPTION: 'Sent when a project has been submitted',
  PROJECT_STARTED_NAME: 'project_submitted',
  QA_PROJECT_STARTED: 'translation-started-checkbox',
  PROJECT_DELAYED_EVENT: 'Project delayed',
  PROJECT_DELAYED_DESCRIPTION: 'Sent when a project is delayed',
  PROJECT_DELAYED_NAME: 'project_delayed',
  QA_PROJECT_DELAYED: 'translation-delayed-checkbox',
  NEW_COMMENT_EVENT: 'New comment on the discussion board',
  NEW_COMMENT_DESCRIPTION: 'Sent when the translator posts a new comment on the discussion board.',
  NEW_COMMENT_NAME: 'new_comment',
  QA_NEW_COMMENT: 'new-comment-checkbox',
  APPROVE_TIME_EVENT: 'Approve additional time',
  APPROVE_TIME_DESCRIPTION:
    'Sent when the translator asks for additional time. By disabling this notification, you agree to changes in project delivery times.',
  APPROVE_TIME_NAME: 'approve_time',
  QA_APPROVE_TIME: 'approve-time-checkbox',
  WORD_COUNT_EVENT: 'Word count difference',
  WORD_COUNT_DESCRIPTION:
    'The translator will indicate that the actual word count is different from the original project word count. You may need to pay the difference for the additional words in order to complete the project.',
  WORD_COUNT_NAME: 'word_count_diff',
  QA_WORD_COUNT: 'word-count-difference-checkbox',
  PROJECT_READY_EVENT: 'Project ready',
  PROJECT_READY_DESCRIPTION: 'Sent when your project is ready',
  PROJECT_READY_NAME: 'project_signed',
  QA_PROJECT_READY: 'project-signed-checkbox',
  CALLBACK_FAILED_EVENT: 'Callback failed',
  CALLBACK_FAILED_DESCRIPTION: 'Send email notification about failed callback immediately at failure.',
  CALLBACK_FAILED_NAME: 'failed_callback',
  QA_CALLBACK_FAILED: 'callback-failed-checkbox',
  QA_SUBMIT_MAILING: 'submit-mailing-preferences-button',
  ADDITIONAL_RECIPIENTS_TITLE: 'Additional Recipients',
  ADDITIONAL_RECIPIENTS_DESCRIPTION:
    'In addition to your primary email address you may enter below other emails that shall receive the translations when ready.',
  PROJECT_NOTIFICATION_TITLE: 'Project Notifications',
  QA_ADD_EMAIL_BTN: 'add-additional-email-button',
  QA_REMOVE_EMAIL_BTN: 'remove-additional-email-button',
  QA_ADDITIONAL_EMAIL: 'additional-email',
  QA_ADDITIONAL_EMAIL_TBL: 'additional-email-tbl',
  PROJECT_EVENT: 'Project Event',
  DESCRIPTION: 'Description',
  NOTIFY: 'Notify',
};

export const WELCOME_MESSAGE = (companyName: string) =>
  `Woo-hoo! Welcome to ${companyName} offering global translations - that resonate locally.`;
