import React, {useEffect, useCallback, useMemo, MouseEvent} from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import {autorun} from 'mobx';
import {observer} from 'mobx-react';
import BaseLoader from 'components/BaseComponents/BaseLoader';
import ProjectsDateFilter from './ProjectsDateFIlter';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import {useStores} from 'hooks/useStores';

const ProjectsPage = observer(() => {
  const {LmDashboardProjectsStore: store} = useStores();
  const firstElementPaginationIndex = store.info.currentPage - 6 <= 0 ? 0 : store.info.currentPage - 5;
  const lastElementPaginationIndex = store.info.currentPage < 5 ? 10 : store.info.currentPage + 6;
  const pagesArray =
    store.info.pageNumbers && store.info.pageNumbers.slice(firstElementPaginationIndex, lastElementPaginationIndex);

  const slug: any = useParams();

  useEffect(
    () =>
      autorun(() => {
        store.setType(slug.type);
        store.getExpertisesList();
      }),
    [slug]
  );

  const onFilter = useCallback((type: string) => store.setActiveFilter(type), []);

  const onSort = useCallback((field: string) => store.changeSort(field), []);

  const setNextOrPreviousPage = useCallback((pageNumber: number) => store.changePage(pageNumber), [store]);

  const handlePaginationControlClick = useCallback(
    ({preventDefault}: MouseEvent) => {
      preventDefault();
      setNextOrPreviousPage(store.info.currentPage);
    },
    [setNextOrPreviousPage, store.info.currentPage]
  );

  const getActualClassName = useCallback(
    (flag: string) => `btn btn-sm mr-2 ${store.activeFilter === flag ? 'active' : 'btn-primary'}`,
    [store.activeFilter]
  );

  const buttonsParams = useMemo(
    () => [
      {
        name: 'Editing',
        classFlag: 'PostTranslationProofreadingProject',
        clickFlag: 'editing',
      },
      {
        name: 'MyTeam',
        classFlag: 'exclusive',
        clickFlag: 'exclusive',
      },
      {
        name: 'Translation',
        classFlag: 'translation',
        clickFlag: 'translation',
      },
      {
        name: 'All',
        classFlag: '',
        clickFlag: '',
      },
    ],
    []
  );

  return (
    <Wrapper container>
      <Projects>
        <h4 className="page__subtitle mb-4">{store.info.title}</h4>
        {store.info.loadData ? (
          <LoaderWrapper>
            <BaseLoader />
          </LoaderWrapper>
        ) : (
          <section className="projects">
            <div className="mb-3 options-wrapper">
              {buttonsParams.map(({name, classFlag, clickFlag}) => (
                <button key={name} className={getActualClassName(classFlag)} onClick={() => onFilter(clickFlag)}>
                  {name}
                </button>
              ))}
              <ProjectsDateFilter />
            </div>
            <table className="dashboard__table table table-striped mb-4">
              <thead className="thead-dark">
                <tr>
                  <th scope="col" className="align-middle text-center pointer" onClick={() => onSort('id')}>
                    ID
                  </th>
                  <th scope="col" className="align-middle text-center">
                    Source Language
                  </th>
                  <th scope="col" className="align-middle text-center">
                    Target Language
                  </th>
                  <th scope="col" className="align-middle text-center pointer" onClick={() => onSort('count')}>
                    Word Count
                    <span className="lang-pair-sort">
                      <span className="glyphicon glyphicon-arrow-up"></span>
                      <span className="glyphicon glyphicon-arrow-down"></span>
                    </span>
                  </th>
                  <th scope="col" className="align-middle text-center pointer" onClick={() => onSort('expertise')}>
                    Expertise
                  </th>
                  <th scope="col" className="align-middle text-center pointer" onClick={() => onSort('created_date')}>
                    Date of submition
                  </th>
                  <th scope="col" className="align-middle text-center">
                    Allocated translators
                  </th>
                </tr>
              </thead>
              <tbody>
                {store.info.filteredProjects &&
                  store.info.filteredProjects.map(
                    ({
                      id,
                      type,
                      exclusive,
                      source_language,
                      target_language,
                      word_count,
                      expertise,
                      date_submition,
                      allocation,
                    }: any) => (
                      <tr key={id}>
                        <td className="text-center project-id">
                          <a href={`/project/${id}`} target="_blank" rel="noopener noreferrer">
                            {id}
                          </a>

                          {type === 'PostTranslationProofreadingProject' && <span className="project-type e">E</span>}

                          {exclusive > 0 && <span className="project-type mt">MT</span>}

                          {type === 'translation' && <span className={'project-type trans'}>TR.</span>}
                        </td>
                        <td className="text-center">
                          <div className="lang-block">
                            <i className={`iconFlag lang-${source_language}`} />
                            <span>{source_language.charAt(0).toUpperCase() + source_language.slice(1)}</span>
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="lang-block">
                            <i className={`iconFlag lang-${target_language}`} />
                            <span>{target_language.charAt(0).toUpperCase() + target_language.slice(1)}</span>
                          </div>
                        </td>
                        <td className="text-center">{word_count}</td>
                        <td className="text-center expertise_cell" title={expertise}>
                          {expertise}
                        </td>
                        <td className="text-center">{date_submition}</td>
                        <td className="text-center">{allocation}</td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>
            <div className="pagination">
              {!!pagesArray.length && (
                <>
                  <span
                    className={`pagination-control ${store.info.currentPage === 0 && 'link-disabled'}`}
                    onClick={handlePaginationControlClick}
                  >
                    &#60;&#60;
                  </span>
                  {pagesArray.map((element) => (
                    <Link
                      href="#"
                      className={store.info.currentPage === element - 1 ? 'pageNumber active' : 'pageNumber'}
                      onClick={({preventDefault}: MouseEvent) => {
                        preventDefault();
                        store.changePage(element);
                      }}
                      key={element}
                    >
                      {element}
                    </Link>
                  ))}
                  <span
                    className={`pagination-control ${
                      store.info.currentPage === store.info.pageNumbers.length - 1 && 'link-disabled'
                    }`}
                    onClick={({preventDefault}: MouseEvent) => {
                      preventDefault();
                      setNextOrPreviousPage(store.info.currentPage + 2);
                    }}
                  >
                    &#62;&#62;
                  </span>
                </>
              )}
            </div>

            {store.info.filteredProjects.length === 0 && (
              <div className="text-center m-5">Looks like everything was handled. We cannot find any projects</div>
            )}
          </section>
        )}
      </Projects>
    </Wrapper>
  );
});

const Wrapper = styled(Grid)`
  height: 100%;
`;

const LoaderWrapper = styled.div`
  height: calc(100% - 70px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Projects = styled.section`
  width: 100%;
  height: 100%;

  &.table tr:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.5);
  }

  &.table thead th {
    font-size: 13px;
    text-transform: none;
    font-weight: 400;
    background-color: ${({theme}) => theme.colors.grey090};
    border-color: ${({theme}) => theme.colors.grey090};
  }

  & .table td {
    font-size: 13px;
  }

  & .project-id {
    position: relative;
    padding-left: 20px;
  }

  & .pagination {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    & .pageNumber {
      color: ${({theme}) => theme.colors.grey100};
      float: left;
      cursor: pointer;
      padding: 8px 16px;
      text-decoration: none;
      transition: background-color 0.3s;
      display: inline-block;
    }

    & :hover:not(.active) {
      background-color: ${({theme}) => theme.colors.green054};
    }

    & .active {
      background-color: ${({theme}) => theme.colors.green054};
      color: ${({theme}) => theme.colors.grey000};
    }
  }

  & .pagination-control {
    color: inherit;
    user-select: none;

    &:hover {
      cursor: pointer;
    }
  }

  & .link-disabled {
    pointer-events: none;
    cursor: default;
  }

  & .translator-name .top {
    display: block;
    position: absolute;
    background: ${({theme}) => theme.colors.blue093};
    color: ${({theme}) => theme.colors.grey000};
    font-size: 12px;
    line-height: 16px;
    left: 0;
    top: 0;
    bottom: 0;
    width: 15px;
    transform: rotate(180deg);
    writing-mode: vertical-rl;
  }

  & .project-type {
    display: block;
    position: absolute;
    background: ${({theme}) => theme.colors.blue093};
    color: ${({theme}) => theme.colors.grey000};
    font-size: 12px;
    line-height: 16px;
    left: 0;
    top: 0;
    bottom: 0;
    width: 15px;
    transform: rotate(180deg);
    writing-mode: vertical-rl;
  }

  & .project-type.e {
    background-color: ${({theme}) => theme.colors.orange090};
  }

  & .project-type.trans {
    background-color: rgb(99, 69, 196);
  }

  & .projects {
    position: relative;
  }

  & .options-wrapper {
    display: flex;
  }

  & .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .pointer {
    cursor: pointer;
  }

  & .expertise_cell {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 10em;
  }
`;

export default ProjectsPage;
