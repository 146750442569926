export const isFullNameValid = (name: string) => /^\s*([A-Za-z]+([.,] |[-']| ))+[A-Za-z]+\.?\s*$/.test(name.trim());

export const isEmailValid = (email: string) =>
  !email.includes(' ') &&
  /[a-z\d!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z\d!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z\d](?:[a-z\d-]*[a-z\d])?\.)+[a-z\d](?:[a-z\d-]*[a-z\d])?/.test(
    email.trim()
  );

export const NameValidation = (name: string) => name?.replace(/[^a-z/\s/]/gi, '');

export const phoneValidation = (value: string) => /^[0-9-()-- ]*$/.test(value);
