import React from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import BaseLoader from 'components/BaseComponents/BaseLoader';
import Chart from 'react-apexcharts';
import Grid from '@mui/material/Grid';
import {useStores} from 'hooks/useStores';
import {smallFontSize, regularWeight} from 'theme/fonts';
import Consts from '../../LmDashboardConsts';

const {BAR_CHART_OPTIONS} = Consts;

const InProgress = observer(() => {
  const {LmDashboardMainStore} = useStores();
  const {
    inProgerssChart: {loading, series, total},
  } = LmDashboardMainStore;
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Grid item xs={12} md={6}>
      <Wrapper onClick={() => navigate(`${location?.pathname}inprogress/projects`)}>
        {loading ? (
          <BaseLoader />
        ) : (
          <>
            <Content>
              <Title>In progress / Started projects</Title>
              <ChartWrapper>
                <Chart options={BAR_CHART_OPTIONS} series={series} type="bar" width="100%" />
              </ChartWrapper>
            </Content>
            <Detail>
              <Widget>
                Out of <Bold>{total}</Bold> projects
              </Widget>
            </Detail>
          </>
        )}
      </Wrapper>
    </Grid>
  );
});

const Wrapper = styled.div`
  border: 1px solid ${({theme}) => theme.colors.grey065};
  background-color: ${({theme}) => theme.colors.grey000};
  cursor: pointer;
  height: 100%;
  padding: 10px;
  position: relative;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 100%;
  padding-right: 60px;

  .apexcharts-toolbar {
    display: none;
  }
`;

const Title = styled.h5`
  margin: 0;
  font-size: 13px;
  font-weight: 700;
`;

const ChartWrapper = styled.div`
  position: relative;
  left: -20px;
`;

const Detail = styled.div`
  position: absolute;
  right: 10px;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  max-width: 80px;
`;

const Widget = styled.div`
  ${smallFontSize};
  ${regularWeight};
  color: ${({theme}) => theme.colors.grey085};
  text-align: right;
  margin: 7px 0;
`;

const Bold = styled.span`
  font-weight: 800;
  color: ${({theme}) => theme.colors.blue093};
`;

export default InProgress;
