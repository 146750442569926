export default {
  RESOURCE_TYPES: {
    FREE: 'free',
    DETAILED: 'detailed',
    ADS: 'ads',
    KYC: 'kyc',
  },
};

export enum KycVersionTypes {
  pkyc1 = 'pkyc/1',
  pkyc2 = 'pkyc/2',
}
