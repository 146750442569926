import React from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import BaseLoader from 'components/BaseComponents/BaseLoader';
import Grid from '@mui/material/Grid';
import {useStores} from 'hooks/useStores';
import {smallFontSize, regularWeight} from 'theme/fonts';

const LowQuality = observer(() => {
  const {LmDashboardMainStore} = useStores();
  const {
    lowQualityChart: {loading, lowQualityClass, total},
  } = LmDashboardMainStore;
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Grid item xs={12} md={6}>
      <Wrapper onClick={() => navigate(`${location?.pathname}low_quality/projects`)}>
        {loading ? (
          <BaseLoader />
        ) : (
          <>
            <Content>
              <Title>Projects with low quality complaint:</Title>
              <Rate>
                {total}
                <div className={lowQualityClass} />
              </Rate>
            </Content>
            <Detail>
              <Widget>
                For the <Bold>last 7</Bold> days
              </Widget>
            </Detail>
          </>
        )}
      </Wrapper>
    </Grid>
  );
});

const Wrapper = styled.div`
  border: 1px solid ${({theme}) => theme.colors.grey065};
  background-color: ${({theme}) => theme.colors.grey000};
  cursor: pointer;
  height: 100%;
  padding: 10px;
  position: relative;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 100%;
`;

const Title = styled.h5`
  margin: 0;
  font-size: 13px;
  font-weight: 700;
`;

export const Rate = styled.div`
  color: ${({theme}) => theme.colors.grey090};
  ${regularWeight};
  height: 90%;
  display: flex;
  padding-bottom: 20px;
  font-size: 32px;
  align-items: center;
  justify-content: center;
`;

const Detail = styled.div`
  position: absolute;
  right: 10px;
  top: 0;
  display: flex;
  align-items: center;
  height: 100%;
  width: 80px;
`;

const Widget = styled.div`
  ${smallFontSize};
  ${regularWeight};
  color: ${({theme}) => theme.colors.grey085};
  text-align: right;
`;

const Bold = styled.span`
  font-weight: 800;
  color: ${({theme}) => theme.colors.blue093};
`;

export default LowQuality;
