export default {
  LM_MENU_ITEMS: [
    {
      id: 1,
      name: 'Home',
      link: '/lm_dashboard/',
    },
    {
      id: 2,
      name: 'Waiting / Delayed / Urgent projects',
      link: '/lm_dashboard/waiting/projects',
    },
    {
      id: 3,
      name: 'In progress / Started projects',
      link: '/lm_dashboard/inprogress/projects',
    },
    {
      id: 4,
      name: 'Delayed projects',
      link: '/lm_dashboard/delayed_projects/projects',
    },
    {
      id: 5,
      name: 'Projects with low quality',
      link: '/lm_dashboard/low_quality/projects',
    },
    {
      id: 6,
      name: 'Projects with low QA',
      link: '/lm_dashboard/low_qa/projects',
    },
    {
      id: 7,
      name: 'Projects with low LL reviews',
      link: '/lm_dashboard/low_ll/projects',
    },
    {
      id: 8,
      name: 'Expired Projects',
      link: '/lm_dashboard/expired/projects',
    },
  ],
  BAR_CHART_OPTIONS: {
    chart: {
      id: 'basic-bar',
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      y: {
        formatter: function (value: any) {
          return String(value);
        },
      },
    },
    xaxis: {
      title: {
        text: '',
      },
      axisBorder: {
        show: true,
        height: 3,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      title: {
        text: '',
      },
      axisBorder: {
        show: true,
        width: 3,
      },
      labels: {
        show: false,
      },
    },
  },
};
